import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { db } from '../../utils/firebase';
import { collection, addDoc } from 'firebase/firestore';

const CTAAlt: React.FC<any> = ({ children, order, textContent }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const storeEmail = async () => {
    try {
      setError('');
      setSuccess('');
      if (!email.toLowerCase().match(regex)) {
        throw 'Please enter a valid email';
      }
      await addDoc(collection(db, 'newsletter'), {
        email: email,
        site: 'grapes',
      });
      setSuccess('You are subscribed to Grapes Newsletter!');
    } catch (e) {
      setError(e);
    }
  };

  const text = (
    <Grid item sx={{ mt: 4 }} xs={12} md={12}>
      <Stack spacing={2}>
        <Typography variant={'h2'}>{textContent.title}</Typography>
        <Typography variant={'body1'}>{textContent.subtitle}</Typography>
        {textContent.title.includes('latest news') && (
          <Grid container sx={{ maxWidth: '100%' }}>
            <Grid item xs={12} md={8}>
              <TextField
                id="outlined-basic"
                label="Email address"
                variant="outlined"
                sx={{
                  width: '100%',
                  mb: 2,
                }}
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                variant={'contained'}
                onClick={storeEmail}
                sx={{
                  borderRadius: "8px",
                  ml: { xs: 0, md: 2 },
                  mb: 2,
                  fontSize: "15px"
                }}
              >
                Subscribe
              </Button>
            </Grid>
            {error && (
              <Typography
                sx={{
                  backgroundColor: '#F8E8FF',
                  borderRadius: 0.5,
                  color: '#D3080C',
                  padding: 1,
                }}
              >
                {error}
              </Typography>
            )}
            {success && (
              <Typography
                sx={{
                  backgroundColor: '#F8E8FF',
                  borderRadius: 0.5,
                  color: '#BC19FF',
                  padding: 1,
                }}
              >
                {success}
              </Typography>
            )}
          </Grid>
        )}
      </Stack>
    </Grid>
  );
  return (
    <Container
      sx={{ transform: (order === 0 || isSmallScreen) && 'rotate(0.5deg)' }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {text}
      </Grid>
    </Container>
  );
};

export default CTAAlt;
