import * as React from 'react';
import {
  Typography,
  Box,
  Container,
  Grid,
  Stack,
  useMediaQuery,
  Link,
} from '@mui/material';

const CallToAction: React.FC<any> = ({ textContent }) => {
  let cta;
  const [nthChild, setNthChild] = React.useState(1);
  const nthChildRef = React.useRef(nthChild);

  return (
    <Container sx={{ pb: 14, pt: 12 }}>
      <Stack
        spacing={2}
        sx={{
          alignItems: 'center',
        }}
      >
        <Typography
          align={
            useMediaQuery((theme: any) => theme.breakpoints.down('md'))
              ? 'left'
              : 'center'
          }
          sx={{
            fontWeight: 600,
            fontSize: { xs: '30px', md: '36px' },
            lineHeight: { xs: '34px', md: '44px' },
          }}
        >
          {textContent.slogan}
        </Typography>
      </Stack>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        ref={(c) => (cta = c)}
      >
        <Grid item sx={{ mt: 8 }} xs={12} md={6}>
          <Stack
            sx={{
              [String.raw`&>*:nth-of-type(${nthChildRef.current})`]: {
                color: 'black',
              },
            }}
            spacing={5}
          >
            {textContent.ctaItems.map((e: any, i: any) => (
              <Box
                onMouseEnter={(e) => {
                  nthChildRef.current = i + 1;
                  setNthChild(nthChildRef.current);
                }}
                key={`ctaBox-${i + 1}`}
                sx={{
                  color: '#A5A4A5',
                  cursor: 'pointer',
                }}
              >
                <Typography
                  variant={'h3'}
                  sx={{
                    lineHeight: '24px',
                    mb: 2,
                  }}
                >
                  {e.item.title}
                </Typography>
                <Typography variant="body1">{e.item.subText}</Typography>
                <> {e.item.link ?  <Link
                    href={e.item.link}
                    target="_blank"
                    sx={{ fontWeight: 500, textDecoration: 'none', display: 'flex', mt: 2 }}
                  >
                    Learn more here
                    <Box component='img' src='/purple-arrow.svg' sx={{ ml: 1 }} />
                  </Link> : <></>}
                </>
              </Box>
            ))}
          </Stack>
        </Grid>
        <Grid item md={6}>
          <Box
            component="img"
            sx={{
              maxHeight: { md: '85%' },
              maxWidth: { xs: '100%', md: '85%' },
              float: { md: 'right' },
              mt: 8.5,
            }}
            src={textContent.ctaItems[nthChildRef.current - 1].item.image}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CallToAction;