import '@fontsource/inter';
import {
  CssBaseline,
  ThemeProvider,
  useMediaQuery
} from '@mui/material';
import { graphql, HeadFC } from 'gatsby';
import * as React from 'react';
import ReactGA from 'react-ga4';
import CallToAction from '../common/components/CallToAction';
import CTACarousel from '../common/components/CallToActionMob';
import CTAAlt from '../common/components/CTAAlt';
import Footer from '../common/components/Footer';
import Hero from '../common/components/Hero';
import Navbar from '../common/components/Navbar';
import Partners from '../common/components/Partners';
import Steps from '../common/components/Steps';
import { theme } from '../ui';

ReactGA.initialize("G-96W2PGHRCE");
ReactGA.send("pageview");

const IndexPage = ({ data }: any) => {
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navbar />
      <Hero textContent={data.textContentYaml.homeMainSection} />
      <Partners textContent={data.textContentYaml.homePartnersSection} />
      <Steps textContent={data.textContentYaml.homeStepsSection} />
      {!isSmallScreen ? (
        <CallToAction textContent={data.textContentYaml.homeCTASection} />
      ) : (
        <CTACarousel textContent={data.textContentYaml.homeCTASection} />
      )}
      <CTAAlt order={1} textContent={data.textContentYaml.homeCTA4Section} />
      <Footer />
    </ThemeProvider>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title id="landing">Grapes: Digital Money, FX and Payments. Fast and Affordable.</title>;

export const query = graphql`
  query {
    textContentYaml {
      title
      homeMainSection {
        heroItems {
          item {
            order
            title
            subText
            image
            link
          }
        }
      }
      homeFeatureSection {
        title
        features {
          item {
            title
            subText
            icon
          }
        }
      }
      homeMSBSolutionsSection {
        title
        subtitle
        link
        featureList {
          item {
            order
            title
            subText
            link
            icon
          }
        }
      }
      homeCTASection {
        slogan
        ctaSubText
        ctaItems {
          item {
            title
            image
          }
        }
      }
      homeCTA3Section {
        title
        subtitle
        image
      }
      homeCTA2Section {
        title
        subtitle
        image
      }
      homeCTA4Section {
        title
        subtitle
      }
      homeStepsSection {
        title
        steps {
          item {
            title
            subtitle
            icon
          }
        }
      }
      homeComparisonSection {
        slogan
        ctaSubText
        link
        disclaimer1
        disclaimer2
        image
        mobile {
          item {
            image
          }
        }
      }
    }
  }
`;
