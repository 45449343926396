import * as React from "react";
import {
  Typography,
  Box,
  Container,
  Stepper,
  Step,
  StepLabel,
  StepIconProps,
  useMediaQuery,
  Link,
  Grid,
} from "@mui/material";

const Steps: React.FC<any> = ({ children, textContent }) => {
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("md")
  );

  return (
    <Box sx={{ pb: 8, pt: 8, backgroundColor: "#BC19FF05" }}>
      <Container sx={{ mb: 5 }}>
        <Typography
          align={"center"}
          sx={{
            fontWeight: 600,
            fontSize: { xs: "28px", md: "36px" },
            mb: 4,
            lineHeight: { xs: "38px", md: "44px" },
          }}
        >
          {textContent.title}
        </Typography>

        <Stepper
          alternativeLabel={!isSmallScreen}
          activeStep={3}
          orientation={isSmallScreen ? "vertical" : "horizontal"}
          sx={{ ml: { xs: 3, md: "" } }}
        >
          {textContent.steps.map((s: any) => (
            <Step sx={{ px: { md: 2 } }} key={s.item.title}>
              <StepLabel icon={<Box component="img" src={s.item.icon} />}>
                <Typography
                  align={"center"}
                  sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "28px",
                  }}
                >
                  {s.item.title}
                </Typography>

                <Typography
                  align={"center"}
                  sx={{
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "24px",
                  }}
                >
                  {s.item.subtitle}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          <Link href="https://docs.grapesfinance.com/" target="_blank">
            <Grid sx={{ color: "#BC19FF", fontSize: 16, mt: 4 }}>
              <Typography align={"center"}>
                Click Here to Learn More About Grapes Embedded Digital Money API
                Accounts
                <Box
                  component="img"
                  src="/purple-arrow.svg"
                  sx={{ ml: 1, height: "13px" }}
                />
              </Typography>
            </Grid>
          </Link>
        </div>
      </Container>
    </Box>
  );
};

export default Steps;
