import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from '@mui/material';
import * as React from 'react';
import Slider from 'react-slick';

const CTACarousel: React.FC<any> = ({ children, textContent }) => {
  const settings = {
    dots: true,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Container
      sx={{
        pb: { xs: 10, md: 12 },
        pt: { xs: 10, md: 17 },
        '& .slick-slider': {
          '& .slick-list': {
            '& .slick-track': {
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'stretch',
              '& .slick-slide': {
                display: 'flex',
                alignItems: 'stretch',
                height: 'inherit',
              },
            },
          },
        },
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: { xs: '30px', md: '36px' },
          lineHeight: { xs: '34px', md: '44px' },
          pb: 2,
        }}
      >
        {textContent.slogan}
      </Typography>
      <Slider {...settings}>
        {textContent.ctaItems.map((p: any) => (

          <Card
            square
            elevation={0}
            sx={{
              height: '99%',
              pt: 3,
            }}
          >
            <CardContent sx={{ p: 0 }}>
              <Typography variant={'h3'} sx={{ my: 2, height: 44 }}>
                {p.item.title}
              </Typography>
              <Typography
                sx={{
                  color: '#7b7b7b',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                  my: 2,
                  height: 140,
                  marginBottom: 5
                }}
              >
                {p.item.subText}
              </Typography>

              <CardMedia>
                <Box
                  component="img"
                  sx={{ maxWidth: '100%' }}
                  src={p.item.image}
                />
              </CardMedia>
            </CardContent>
          </Card>
        ))}
      </Slider>
    </Container>
  );
};

export default CTACarousel;