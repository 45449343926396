import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import Slick from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const smallLogos = [
  '/dvchain.svg',
  '/Apaylo.svg',
  '/circle.svg'
]

const Partners: React.FC<any> = ({ textContent }) => {
  const settings = {
    infinite: true,
    autoplay: true,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: 'linear', // cont scrolling pauses/stutters
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div></div>
  );
};

export default Partners;
