// Import the functions you need from the SDKs you need
import { initializeApp, getApps } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.API_KEY,
  authDomain: "grapes-website-78150.firebaseapp.com",
  projectId: "grapes-website-78150",
  storageBucket: "grapes-website-78150.appspot.com",
  messagingSenderId: "407734134487",
  appId: "1:407734134487:web:d05cd67a7b115822a4b292",
  measurementId: "G-96W2PGHRCE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };